import { useEffect } from 'react';
import useSound from 'use-sound';

import { useDispatch, useSelector } from 'react-redux';
import { setAudioPlaying, setHasAudio } from '../state/ui/uiSlice';
import { selectAudioEnabled } from '../state/ui/audioSelectors';
import eventBus from '../lib/EventBus';

interface Props {
  soundUrl: string;
  playOnce?: boolean;
  playOnFirstInteraction?: boolean;
}

export default function SoundOnAddToCart({ soundUrl }: Props) {
  const dispatch = useDispatch();
  const audioEnabled = useSelector(selectAudioEnabled);
  const [play, data] = useSound(soundUrl, {
    soundEnabled: audioEnabled,
  });

  useEffect(() => {
    eventBus.on('cart:add', () => {
      play();
    });
    return () => {
      eventBus.remove('cart:add');
    };
  }, [eventBus, play]);

  useEffect(() => {
    dispatch(setHasAudio(true));
    return () => {
      dispatch(setHasAudio(false));
    };
  }, [setHasAudio, dispatch]);

  useEffect(() => {
    if (data.sound === null) return;
    data.sound.onplay = () => {
      dispatch(setAudioPlaying(true));
    };
    data.sound.onstop = () => {
      dispatch(setAudioPlaying(false));
    };
    data.sound.onend = () => {
      dispatch(setAudioPlaying(false));
    };
  }, [data.sound, dispatch, setAudioPlaying]);

  return null;
}
